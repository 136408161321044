//This needs completely refactoring so it's not monolithic

global.$ = global.jQuery = require('jquery');
global.enquire = require('enquire.js');
global.Mustache = require('mustache');
global.Sortable = require('sortablejs');

//Trumbowyg global svnPath
$.trumbowyg.svgPath = '/assets/js/ui/icons.svg';

var min_800 = false;
var max_799 = true;
var max_899 = true;

/**
 * Initialise media plus objects
 * Needs to have
 * 1) a .media_plus class on the embedded item
 * 2) a data-parts attribute, containing a json object, an array of objects:
 *     {start: 0, end: 120, title: "title goes here"}
 */

//cookies
var expire=new Date();
expire=new Date(expire.getTime()+7*24*60*60*1000);

function analyticsYes() {
    document.cookie="analyticsYes=true; expires="+expire+"; path=/";
}
function analyticsNo() {
    document.cookie="analyticsNo=true; expires="+expire+"; path=/";
}

$('#btn_accept').on('click', function(e) {
    e.preventDefault();
    analyticsYes();
    location.reload();
});

$('#btn_decline').on('click', function(e) {
    e.preventDefault();
    analyticsNo();
    location.reload();
});

// handle the admin controls, row merge
$('body.admin input[type=checkbox].link_it').each(function(){
    $(this).change(function(){
        var $tr = $(this).parents('tr');
        if($(this).prop('checked')) {
            $tr.addClass('link_prev');
        } else {
            $tr.removeClass('link_prev');
        }
    });
    $(this).triggerHandler('change');
});

function initMediaPlus()
{

    // specific to this fn
    function timeToSeconds(time) {

        if(time == null) {
            return 0;
        }

        var expl = time.split(":");
        return parseInt(expl[1]) + (60 * parseInt(expl[0]));
    }


    function setPause($context) {
        $('.pause_play .ico', $context).removeClass('i_pause');
        $('.pause_play .ico', $context).addClass('i_play');
    }

    function setPlay($context) {
        $('.pause_play .ico', $context).removeClass('i_play');
        $('.pause_play .ico', $context).addClass('i_pause');
    }

    function setPosition($context, pc) {
        $('.position', $context).css("left", pc+"%");
    }

    /**
     * Loop through all media plus elements on the page
     */
    var $mps = $('.media_plus');
    $mps.each(function(){

        var titles = [];

        var parts = $(this).data('parts');

        var $ctrls = $('.ctrls ul', $(this));

        //$ctrls.html('');

        var $ctrl_items = $('');

        function setParts(duration) {

            for(var p in parts) {

                var part = parts[p];
                var start = timeToSeconds(part.time);
                titles[start] = part.title;

                if(part.merge) {
                    continue;
                }

                var nextPart = parts[parseInt(p)+1];

                if(nextPart) {

                    //console.log(nextPart);
                    var end = duration;

                    // loop through all "next part" and determine how
                    // many need merged/skipped
                    for(var i = parseInt(p) + 1; i < parts.length; i++) {
                        if(parts[i].merge) {
                            continue;
                        } else {
                            end = timeToSeconds(parts[i].time);
                            break;
                        }
                    }
                } else {
                    var end = duration;
                }

                if(end > duration) {
                    // prevent values longer than the video
                    end = duration;
                }

                if(start > duration) {
                    // we've gone past the end. quit
                    return;
                }

                var pc = ((end-start) / duration) * 100;
                if(pc > 100) {
                    pc = 100;
                }

                var $ctrl = $("<li data-start='"+start+"' data-end='"+end+"' style='width:"+pc+"%'><span>"+part.title+"</span></li>");

                $ctrl.on('click touchend', function(e){

                    // determine horizontal distance clicked
                    var elLeft = $(this).offset().left;
                    var pageLeft = e.pageX;

                    var timeOffsetPc = (pageLeft - elLeft) / $(this).width();

                    var start = $(this).data('start');
                    var end = $(this).data('end');

                    if(loopFrom !== false && loopTo !== false) {
                        // if currently looping, change to loop new part
                        $ctrl_items.removeClass('looping');
                        $(this).addClass('looping');
                        loopFrom = start;
                        loopTo = end;
                    }
                    var ctrlDuration = end - start;
                    var setTime = start + (timeOffsetPc * ctrlDuration);
                    $this.trigger('setPlayerTime', setTime);


                });

                $ctrls.append($ctrl);

                $ctrl_items = $('li', $ctrls);
            }
        }

        // track progress, to loop current segment if required.
        var loopFrom = loopTo = false;
        var progress = 0;
        var playerDuration = 0;

        $('.btn.loop', $(this)).on('click touchend', function(e) {
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
                loopFrom = loopTo = false;
                $ctrl_items.removeClass('looping');
            } else {
                $(this).addClass('active');
                $ctrl_items.removeClass('looping');
                $ctrl_items.each(function(){
                    if($(this).data('end') > progress) {
                        $(this).addClass('looping');
                        loopFrom = $(this).data('start');
                        loopTo = $(this).data('end');
                        return false;
                    }
                });
            }
            e.preventDefault();
            return false;
        });

        $('.btn.prev', $(this)).on('click touchend', function(e){
            // go to next control part and play from there
            $ctrl_items.each(function(i, el) {
                var start = $(this).data('start');
                var end = $(this).data('end');

                if(progress >= start && progress < start + 1) {
                    // find next in sequence
                    var $prevItem = $ctrl_items.eq(i - 1);
                    if($prevItem.length && $prevItem.index() < i) {
                        var jumpTo = $prevItem.data('start');
                        $this.trigger('setPlayerTime', jumpTo);
                        progress = jumpTo;
                        if(loopFrom !== false && loopTo !== false) {
                            // if currently looping, change to loop new part
                            loopFrom = $prevItem.data('start');
                            loopTo = $prevItem.data('end');
                            $ctrl_items.removeClass('looping');
                            $prevItem.addClass('looping');
                        }
                        return false;
                    }
                }

                if(progress < end && progress > start) {
                    $this.trigger('setPlayerTime', start);
                    //player.setCurrentTime(start);
                    progress = start;
                    return false;
                }
            });
            e.preventDefault();
            return false;
        });

        $('.btn.next', $(this)).on('click touchend', function(e){
            // go to next control part and play from there
            $ctrl_items.each(function(){
                var start = $(this).data('start');
                if(start > progress) {
                    //player.setCurrentTime(start);
                    $this.trigger('setPlayerTime', start);
                    // set new loops

                    var start = $(this).data('start');
                    var end = $(this).data('end');

                    if(loopFrom !== false && loopTo !== false) {
                        // if currently looping, change to loop new part
                        loopFrom = start;
                        loopTo = end;
                        $ctrl_items.removeClass('looping');
                        $(this).addClass('looping');
                    }

                    progress = start;
                    return false;
                }
            });
            e.preventDefault();
            return false;
        });

        $('.btn.pause_play', $(this)).on('click touchend', function(e){
            $this.trigger('pausePlay');
            e.preventDefault();
            return false;

        });

        var $this = $(this);

        function setTitle(t) {
            for(var time in titles) {
                var title = titles[time];
                if(parseFloat(t) > parseFloat(time)) {
                    $('h3', $this).html(title);
                }
            }
        }


        if($(this).hasClass('media_plus--vimeo')){

            var $vimeo = $('.vimeo-iframe', $(this));
            var player = new Vimeo.Player($vimeo);

            player.on('pause', function(){
                setPause($this);
            });

            player.on('play', function(){
                setPlay($this);
            });

            player.on('timeupdate', function(data){
                setPosition($this, data.percentage);
            });

            player.on('ended', function(){
                // if looping
                if(loopFrom !== false && loopTo !== false) {
                    setTimeout(function(){
                        progress = loopFrom;
                        player.setCurrentTime(loopFrom);
                        player.play();
                    }, 0);
                }
            });

            // a hacky workaround, timeupdate above isn't working at the moment
            setInterval(function(){
                player.getCurrentTime().then(function(s){
                    var pc = s / playerDuration * 100;
                    $('.position', $this).css("left", pc + "%");
                    progress = s;
                    setTitle(s);
                });
                // watch for looping
                //console.log('Checking loop %f %f %f', progress, loopFrom, loopTo);

                if(loopFrom !== false && loopTo !== false) {
                    if(progress >= loopTo - 0.25 || progress > playerDuration - 0.5) {
                        progress = loopFrom;
                        player.setCurrentTime(loopFrom);
                    }
                }
            }, 100);

            $this.on('setPlayerTime', function(e, t){
                player.setCurrentTime(t).then(function(s){
                    setTitle(t);
                }).catch(function(error){
                    switch (error.name) {
                        case 'RangeError':
                            // the time was less than 0 or greater than the video’s duration
                            break;

                        default:
                            // some other error occurred
                            break;
                    }
                });
            });

            $this.on('pausePlay', function(){
                player.getPaused().then(function(paused){
                    if(!paused) {
                        player.pause();
                    } else {
                        player.play();
                    }
                }).catch(function(error){

                });
            });



            // Grab the video duration and init the part divs
            player.getDuration().then(function(duration) {
                playerDuration = duration;
                setParts(duration);
            }).catch(function(error) {
                // an error occurred
            });


        } else if($(this).hasClass('media_plus--audio')) {

            var $audio = $('audio', $(this));

            var el = $audio[0];

            el.addEventListener("loadedmetadata", function(e) {
                var duration = el.duration;
                setParts(duration);
            });

            el.addEventListener('ended', function(e) {
                if(loopFrom !== false && loopTo !== false) {
                    progress = loopFrom;
                    el.currentTime = loopFrom;
                    el.play();
                }
            });

            el.addEventListener('timeupdate', function(e) {
                setPosition($this, (el.currentTime / el.duration) * 100);
                setTitle(el.currentTime);
                progress = el.currentTime;
                // watch for looping
                //console.log('Checking loop %f %f %f', progress, loopFrom, loopTo);
                if(loopFrom !== false && loopTo !== false) {
                    if(progress >= loopTo) {
                        progress = loopFrom;
                        el.currentTime = loopFrom;
                    }
                }
            });

            $(this).on('setPlayerTime', function(e, t) {
                el.currentTime = t;
            });

            $(this).on('pausePlay', function(e) {
                if(el.paused) {
                    el.play();
                    setPlay($this);
                } else {
                    el.pause();
                    setPause($this);
                }
            });

        }

    });
}

$(document).ready(function() {
    // Initialise datetime picker elements
    $('input[type=date],input[type=datetime],input[type=datetime-local]').each(function(){
        var alt = $(this).data('alt-date');
        var date = new Date($(this).val());
        $(this).prop('type', 'text');
        $(this).datepicker({
            dateFormat: 'dd MM, yy',
            altField: 'input[name="'+alt+'"]',
            altFormat: "yy-mm-dd"
        }).datepicker('setDate', date);
    });

    // used in the mediaPlusInit function
    $.fn.reverse = [].reverse;

    if (Modernizr.mq("only all")){
        $("html").addClass("mq");
    } else {
        $("html").addClass("no-mq");
    }

    //svg to png swaps
    if (!Modernizr.svg) {
        $('img[src$=".svg"]').each(function() {
            var $img = $(this);
            $img.attr('src', $img.attr('src').replace(/svg$/, 'png'));
        });
    }

    //form placeholders fallback
    if (!("placeholder" in document.createElement("input"))) {
        $("input[placeholder], textarea[placeholder]").each(function () {
            var val = $(this).attr("placeholder");
            if (this.value === "") {
                this.value = val;
            }
            $(this).focus(function () {
                if (this.value == val) {
                    this.value = "";
                }
            }).blur(function () {
                if ($.trim(this.value) === "") {
                    this.value = val;
                }
            });
        });

        $('form').submit(function () {
            $(this).find("input[placeholder], textarea[placeholder]").each(function () {
                if (this.value == $(this).attr("placeholder")) {
                    this.value = "";
                }
            });
        });
    }

    //remove fallback layout if data-columns
    $(".blog_list, .testimonial_details > div").removeClass("col col_3");

    //get heights for the items (buy individual pieces etc)
    function boxSizes(){

        var maxHeight = -1;

        $(".packs .item > *").height("auto");

        $('.packs .item > *').each(function() {
            maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        });

        $('.packs .item > *').each(function() {
            $(this).height(maxHeight);
        });

    }

    //do box sizes
    $(window).load(function() {

        boxSizes();

    });

    //resizes
    $(window).resize(function() {

        boxSizes();

    }).resize();

    //tabs
    $('.tab_set').tabs();

    //show and hide sub nav on touch
    $("body").click(function() {

        $(".tier_1 > li").removeClass("hover");

    });

    $(".tier_1 > li").on("click", function(e) {
        e.stopPropagation();
        $(this).toggleClass("hover");
        $(this).siblings().removeClass("hover");

    });

    //nav menu mouse leave
    $(".tier_1 > li").mouseleave(function(){

        $(this).removeClass("hover");

    });

    //slideshow
    $('.testimonial_examples .list:not(.no_slider)').slick({
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
        swipe: false,
        speed: 600,
        useCSS: false,
        prevArrow: '<div class="btn btn_default"><a href="javascript:void(0);"><span class="ico i_arrow_left" aria-hidden="true"></span></a></div>',
        nextArrow: '<div class="btn btn_default"><a href="javascript:void(0);"><span class="ico i_arrow_right" aria-hidden="true"></span></a></div>'
    });

    //slideshow
    $('.promotions.slider .list').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 800,
        prevArrow: '<div class="btn btn_default"><a href="javascript:void(0);"><span class="ico i_arrow_left" aria-hidden="true"></span></a></div>',
        nextArrow: '<div class="btn btn_default"><a href="javascript:void(0);"><span class="ico i_arrow_right" aria-hidden="true"></span></a></div>'
    });

    //handle the scroll binds, make back to top visible/invisible depending on the scroll position
    $(".back_top").hide();

    $(window).scroll(function(){
        //handle the scroll to top
        if($(this).scrollTop() > 44){
            $(".back_top").fadeIn();
            $("#cookies_modal").addClass("shift");
        }else{
            $(".back_top").fadeOut();
            $("#cookies_modal").removeClass("shift");
        }
    });
    //animate back to top
    $(".back_top a").on("click", function(){

        $("html,body").animate({scrollTop:0},1000,"easeInOutQuint");
        return false;

    });

    //skip to plans
    $(".intro .btn_dark").on("click", function(){
        $("html,body").animate({scrollTop:$("#plans").position().top},1000,"easeInOutQuint");
        return false;
    });

    //accordion on members nav - must be a better way of doing this :P
    $('.members_navigation .tier_1').each(function() {
        $(this).accordion({
            header: '.has_sub_1',
            active: $(this).find('ul li.active:last').length > 0 || $(this).find('li.default_open').length > 0 ? $(this).find('li.active:last').parents('li').index() - 1 : false,
            icons: false,
            heightStyle: 'content',
            collapsible: true,
            animate: {easing: 'easeInOutQuint', duration: 500}
        });
    });

    $('.members_navigation .tier_2').each(function() {
        $(this).accordion({
            header: '.has_sub_2',
            active: false,
            icons: false,
            heightStyle: 'content',
            collapsible: true,
            animate: {easing: 'easeInOutQuint', duration: 500}
        });
    });

    /*
    //For the shop only. Don't use the shop right now
    if($(".cb").length) {
        $(".cb").colorbox({
            maxWidth:'95%',
            maxHeight:'95%',
            initialWidth: "100",
            initialHeight: "100",
            scrolling: false
        });
    }

    $(".examples").click(function(e){
        var $container = $(this).parents('.item').first();
        var $target = $('.cb', $container);
        if($target) {
            $target.colorbox().eq(0).click();
        }
        e.preventDefault();
    });
    */

    // The main exam practice accordion
    $('.main .accordion .tier_1, .main .accordion .tier_2, .main .accordion .tier_3').each(function() {

        var tierActive = false;

        // for each direct li descendant of this specific ul
        // check if it contains .active anywhere. If so,
        // set this one as active.
        $('> li', $(this)).each(function(){
            if($(this).find('li.active').length > 0) {
                tierActive = $(this).index();
            }
        });

        // todo: is the header class i.e. has_sub_x used for anything
        // specific? Seems to break the accordion functionality.
        // Doesn't seem to affect anything if disabled?
        var headerClass = '';

        if($(this).hasClass('tier_1')) {
            var headerClass = '.has_sub_1';
        } else if($(this).hasClass('tier_2')) {
            var headerClass = '.has_sub_2';
        } else if($(this).hasClass('tier_3')) {
            var headerClass = '.has_sub_3';
        }

        // check if this ul contains further lists, if so
        // turn it into an accordion.
        // If not, it'll just contain links, so don't create
        // an accordion.
        if($(this).find('ul').length > 0) {
            $(this).accordion({
                //header: headerClass,
                active: tierActive,
                icons: false,
                heightStyle: 'content',
                collapsible: true,
                animate: { easing: 'easeInOutQuint', duration: 500 },
            });
        }

        return;
    })

    $('.main .accordion .tier_3').each(function () {
        $(this).accordion({
            header: '.has_sub_3',
            active: $(this).find('li.active').length > 0 ? $(this).find('li.active').parents('li').first().index() : false,
            icons: false,
            heightStyle: 'content',
            collapsible: true,
            animate: { easing: 'easeInOutQuint', duration: 500 }
        });
    });

    //add/remove class if input checked/unchecked
    $('input').on("click", function () {
        $('input:not(:checked)').parent().removeClass("checked");
        $('input:checked').parent().addClass("checked");
    });

    //modal

    // $('a[data-modal]').each(function(){
    //     this.href += '/modal';
    // });

    $('a[data-modal]').click(function(event) {
        $(this).modal({
            zIndex: 9999,
            showClose: false
        });
        return false;
    });

    //switch modal from fixed to absolute position based on window height
    $("body").on($.modal.OPEN, function(event, modal) {

        var div = $(".modal").height();
        var win = $(window).height();

        if (div > win ) {
            //add the class
            $(".modal").addClass("abs");
            //position it in view
            $("html,body").animate({scrollTop:0},1000,"easeInOutQuint");
            return false;
        }

    });

    function placeAd(){

        var ribbonHeight = $('.ribbon_area').outerHeight();
        $('.advert').css('top', ribbonHeight - 195);

    }

    //searchbar

    //show hide lists
    $(".lists").hide();
    $(".lists").css("opacity", "1");
    $(".filter h2").on("click", function() {
        $(this).toggleClass("hover");
        $(".search_bar").parent().toggleClass("blur");
        $(".filter .lists").toggle();
    });
    $(".sort .current").on("click", function() {
        $(this).toggleClass("hover");
        $(".search_bar").toggleClass("blur");
        $(".sort .lists").toggle();
    });
    $(".show .current").on("click", function() {
        $(this).toggleClass("hover");
        $(".search_bar").toggleClass("blur");
        $(".show .lists").toggle();
    });
    //show more in list
    $(".list .more a").on("click", function(e) {
        e.stopPropagation();
        $(this).parent().addClass("hover");
        $(this).parent().hide();
    });

    function mobileOn(){

        $('.navigation, #account, #subnav').hide();
        // $(".btn_menu a, .btn_account a").attr("href", "javascript:void(0)");
        $(".btn_menu a, .btn_account a, .btn_sub a").on("click", function(e) {
            e.preventDefault();
        });

        //for showing and hiding navigation in mobile
        $(".btn_menu").on("click", function() {

            $('#account, #subnav').slideUp(500, 'easeInOutQuint');
            $('.navigation').slideToggle(500, 'easeInOutQuint');

        });

        //for showing and hiding members navigation in mobile
        $(".btn_account").on("click", function() {

            $('.navigation, #subnav').slideUp(500, 'easeInOutQuint');
            $('#account').slideToggle(500, 'easeInOutQuint');

        });

        //for showing and hiding sub navigation in mobile
        $(".btn_sub").on("click", function() {

            $('.navigation, #account').slideUp(500, 'easeInOutQuint');
            $('#subnav').slideToggle(500, 'easeInOutQuint');

        });

        //accordion on nav
        $('.navigation ul').accordion({
            header: '.has_sub',
            active: false,
            icons: false,
            heightStyle: 'content',
            collapsible: true,
            animate: { easing: 'easeInOutQuint', duration: 500 }
        });

        $('.advert').removeAttr('style');

    }

    function mobileOff(){

        $('.navigation ul').accordion('destroy');
        $('.navigation, .members_navigation').show();

        $(".btn_account, .btn_menu").unbind();

        placeAd();

    }

    if(!$('html').hasClass('e')) {

        mobileOn();

    }

    //enquire for media queries

    enquire.register("screen and (min-width:800px)", {

        match: function () {

            mobileOff();

            max_799 = false;
            min_800 = true;

        },
        unmatch: function () {

            mobileOn();

            max_799 = true;
            min_800 = false;

        }

    }).register("screen and (min-width:900px)", {

        match: function () {

            max_899 = false;

        },
        unmatch: function () {

            max_899 = true;

        }

    }).register("screen and (min-width:1200px)", {

        match: function () {

            placeAd();

        },
        unmatch: function () {

            placeAd();

        }

    });

    //Form handling
    /*$.ajaxPrefilter(function(options, originalOptions, xhr) {
          var token = $('meta[name="_token"]').attr('content');

          if (token) {
                return xhr.setRequestHeader('X-XSRF-TOKEN', token);
          }
    });*/

    //Close message box
    $('a.msg_close').on('click', function() {
        $(this).parent().remove();
    });


    var addFile = function() {
        //var count = parseInt($('input[name="repertoire_count"]').val());
        var count = parseInt($(this).attr('data-count'));
        //console.log('Count', count);
        var template = '<tr>\n\
                            <td><input type="text" name="part[{{count}}][]" class="text" placeholder="Name (for e.g. 1st movement)"><div class="note">^ Can be left blank if piece has only a single part / movement</div><input type="file" name="files[{{count}}][]" id="" /></td>\n\
                            <td class="icon_td vabottom"><a href="javascript:void(0);" class="file_remove"><span class="ico i_minus" aria-hidden="true"></span></a></td>\n\
                            <td class="icon_td vabottom"><a href="javascript:void(0);" class="file_add"><span class="ico i_plus" aria-hidden="true"></span></a></td>\n\
                        </tr>';
        //console.log($(this).parent().parent().parent().html());
        var row = $(Mustache.render(template, {count: count}));
        row.find('.file_remove').on('click', removeFile);
        row.find('.file_add').on('click', addFile);
        $(this).parent().parent().parent().append(row);
    };

    var removeFile = function() {
        //console.log('Count', $(this).parent().parent().parent().find('tr').length);
        if($(this).parent().parent().parent().find('tr').length > 1) {
            $(this).parent().parent().remove();
        }
    };

    //Get assessed
    var addRep = function() {
        var template = '<tr>\n\
                            <td>\n\
                            <input type="text" name="composer[{{count}}]" class="text" placeholder="Composer name (for e.g. Beethoven)">\n\
                            <input type="text" name="title[{{count}}]" class="text" placeholder="Piece name (for e.g. Moonlight Sonata)">\n\
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" class="scope_col">\n\
                                    <thead>\n\
                                        <tr>\n\
                                            <th scope="col">Files</th>\n\
                                            <th scope="col" colspan="2" class="sml center middle">Remove or Add Files</th>\n\
                                        </tr>\n\
                                    </thead>\n\
                                    <tbody>\n\
                                        <tr>\n\
                                            <td><input type="text" name="part[{{count}}][]" class="text" placeholder="Name (for e.g. 1st movement)"><div class="note">^ Can be left blank if piece has only a single part / movement</div><input type="file" name="files[{{count}}][]" id="" /></td>\n\
                                            <td class="icon_td vabottom"><a href="javascript:void(0);" class="file_remove"><span class="ico i_minus" aria-hidden="true"></span></a></td>\n\
                                            <td class="icon_td vabottom"><a href="javascript:void(0);" class="file_add" data-count="{{count}}"><span class="ico i_plus" aria-hidden="true"></span></a></td>\n\
                                        </tr>\n\
                                    </tbody>\n\
                                </table>\n\
                            </td>\n\
                        </tr>';
        var count = parseInt($('input[name="repertoire_count"]').val());
        count++;
        $('input[name="repertoire_count"]').val(count);

        var row = $(Mustache.render(template, {count: count}));
        row.find('.repertoire_add').on('click', addRep);
        row.find('.repertoire_remove').on('click', removeRep);
        row.find('.file_remove').on('click', removeFile);
        row.find('.file_add').on('click', addFile);
        $('#assessment-repertoire-body').append(row);

        return false;
    };

    var removeRep = function() {
        if($('#assessment-repertoire-body > tr').length > 1) {
            var count = parseInt($('input[name="repertoire_count"]').val());
            count--;
            $('input[name="repertoire_count"]').val(count);

            $('#assessment-repertoire-body > tr:last').remove();
        }
    };

    $('.repertoire_add').on('click', addRep);
    $('.repertoire_remove').on('click', removeRep);

    $('.file_add').on('click', addFile);
    $('.file_remove').on('click', removeFile);

    $('#assessment-adult').on('change', function() {
        if ($(this).val() === 'yes') {
            $('#assessment-email').show();
            $('#assessment-guardian').hide();
        } else {
            $('#assessment-email').hide();
            $('#assessment-guardian').show();
        }
    });

    //Pieces
    $('#piece-type').on('change', function() {
        var self = this, piece_type_id = $(this).val();
        $.ajax({
            method: 'POST',
            url: '/api/v1/admin/piece-categories',
            data: JSON.stringify({
                piece_type_id: piece_type_id
            }),
            success: function (data) {
                var select = $('#piece-category');
                select.html('<option>Select...</option>');
                for (var key in data) {
                    select.append($('<option>', {value: key}).text(data[key]));
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                //console.log(textStatus);
                alert('Unable to remove media. Please try again');
            }
        });
    });

    $('a.remove_piece_media').on('click', function() {
        var del = confirm('Are you sure you want to remove this media?');
        if (del) {
            var media_id = $(this).attr('data-media-id'), piece_id = $(this).attr('data-piece-id');
            var self = this;
            $.ajax({
                method: 'POST',
                url: '/api/v1/admin/delete-piece-media',
                data: JSON.stringify({
                    media_id: media_id,
                    piece_id: piece_id
                }),
                success: function (data) {
                    $(self).parent().parent().fadeOut('fast', function() {
                        $(this).remove();
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    //console.log(textStatus);
                    alert('Unable to remove media. Please try again');
                }
            });
        }
    });

    $('a.basket_remove').on('click', function() {
        var del = confirm('Are you sure you wish to remove this item from the basket');
        return del;
    });

    $('a.subscription_remove').on('click', function() {
        var del = confirm('Are you sure you wish to remove this subscription');
        return del;
    });

    // Initialise any media-plus elements
    $(document).on('flexContentLoaded', initMediaPlus);

    // From http://stackoverflow.com/questions/19790506/multiple-audio-html-auto-stop-other-when-current-is-playing-with-javascript
    document.addEventListener('play', function(e){
        var audios = document.getElementsByTagName('audio');
        for(var i = 0, len = audios.length; i < len;i++){
            if(audios[i] != e.target){
                audios[i].pause();
            }
        }
    }, true);

    $('form.question_form input').bind('click', function() {
        var answeredAll = true;
        $('ul', $(this).closest('.question_form')).each(function(){
            if($('input:checked', this).length == 0) {
                answeredAll = false;
            }
        });

        if(answeredAll && !$(this).parents('form').next().find('.answered_all').length) {
            $('div.h1 .select_check_switch', $(this).parents('form').next()).fadeOut('fast', function() {
                $(this).text('Check your answers').addClass('answered_all');
                $(this).closest('div.btn').removeClass('disabled');
                $(this).prev().removeClass('i_arrow_up').addClass('i_arrow_down');
                $(this).fadeIn();
            });
        }
    });

    // show/toggle elements
    $('[data-toggle]').each(function(){
        var $target = $('[data-toggle-target="'+$(this).data('toggle')+'"]');
        $target.hide(0);
        $(this).click(function(e){
            $target.toggle();
            e.preventDefault();
            return false;
        });
    })

    var answers = new Array();
    $('form.question_form').on('submit', function() {
        var template = '<div class="answer {{theClass}}">\n\
                <div class="h1"><span class="ico i_answer" aria-hidden="true"></span>{{title}}</div><!--\n\
             --><p>{{answer}}</p>\n\
            </div>';

        var input = $(this).serializeJSON();
        var self = this;
        //console.log(input);
        var parent = $(this).parent();
        $.ajax({
            method: 'POST',
            url: $(this).attr('action'),
            data: input,
            success: function(data) {
                //console.log(data);

                //First lets validate if the question was answered with all the answers
                if (data.error) {
                    return;
                }

                $(self).attr('disabled', true);
                var answersElmId = $('#answers-' + data.question_id);

                for (var i = 0; i < data.parts.length; i++) {
                    var result = data.parts[i];
                    answers.push(parseInt(data.question_id));

                    switch(result.type) {
                        case 'sa':
                            answersElmId.append(Mustache.render(template, {
                                theClass: function () {
                                    switch(result.pass) {
                                        case 1:
                                            return 'correct';
                                            break;
                                        case 2:
                                            return 'neutral';
                                            break;
                                        default:
                                            return 'incorrect';
                                    }
                                },
                                title: function () {
                                    switch(result.pass) {
                                        case 1:
                                            return 'Well done!';
                                            break;
                                        case 2:
                                            return 'Nearly!';
                                            break;
                                        default:
                                            return 'Oh no!';
                                    }
                                },
                                answer: function () {
                                    switch(result.pass) {
                                        case 1:
                                            return 'Keep it up!';
                                            break;
                                        case 2:
                                            return 'Better luck next time';
                                            break;
                                        default:
                                            return 'Don\'t give up';
                                    }
                                },
                                stats: data.stats_html
                            }));
                            break;
                        default:
                            answersElmId.append(Mustache.render(template, {
                                title: result.pass == 1 ? 'correct' : 'incorrect',
                                theClass: result.pass == 1 ? 'correct' : 'incorrect',
                                answer: 'The answer is : "' + result.answer + '"'
                            }));
                            break;
                    }
                }

                answersElmId.append(data.stats_html);

                $('#help-' + data.question_id).fadeIn('slow');

                parent.next().fadeIn('fast');

                //Enable next practice button if last question
                if (data.question_id === parseInt($(self).children('[name="final_question_id"]').first().val())) {
                    var reloader = $('#next-practice-' + $(self).children('[name="practice_test_id"]').first().val());
                    reloader.parent().fadeIn('fast', function() {
                        reloader.on('click', function() {
                            var path = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');

                            if (path.substr(-1) == '/') {
                               path = path.substr(0, path.length -1);
                            }

                            window.location.href =
                                path
                                + '/?' + Math.floor((Math.random() * 1000000) + 1)
                                + '#main';
                        });
                    });
                }
            }
        });
        return false;
    });

    $('a.question_checker').on('click', function() {
        var q = $(this).attr('data-part');
        if (answers.indexOf(parseInt(q)) === -1) {
            $('form[id="question-' + q+ '"]').submit();
        }
    });


    var packageUpdater = function() {
        var groups = $('input[name="grade_group_id"]:checked').map(function() {
            return $(this).val();
        }).get();

        if ($('input[name="exam_board_id"]:checked').val() !== undefined && $('input[name="grade_group_id"]:checked').val() !== undefined) {

            var template = '\n<div class="h1">Chosen plan</div><div class="h2">{{description}} <small>@ {{currency}}{{price}} {{frequency}}</small></div>';

            $.ajax({
                method: 'POST',
                url: '/api/v1/training-package',
                data: JSON.stringify({
                    exam_board_id: $('input[name="exam_board_id"]:checked').val(),
                    grade_group_id: groups
                }),
                success: function(data) {
                    $('#subscribe input[name="training_package_id[]"]').remove();
                    $('#package-details').html('<hr>');
                    $(data).each(function() {
                        $('#package-details').append(Mustache.render(template, {
                            description: this.description,
                            currency: this.currency || '£',
                            price: this.price,
                            frequency: this.recurring === 1 ? 'per ' + this.interval_unit : ' one off payment'
                        }));
                        $('#subscribe').prepend(Mustache.render('<input name="training_package_id[]" type="hidden" value="{{id}}">', {id: this.id}));
                    });
                    $('#package-details').fadeIn('slow');
                }
            });
        } else {
            $('#package-details').html('');
            $('#package-details').fadeOut('slowly');
            $('#subscribe input[name="training_package_id[]"]').remove();
        }
    };

    $('input[name="grade_group_id"]').on('change', function() {
        packageUpdater();
    });
    $('input[name="exam_board_id"]').on('change', function() {
        packageUpdater();
    });

    $('#subscribe').on('submit', function() {
        return $(this).children('input[name="training_package_id[]"]').length > 0;
    });

    $('#subscribe-confirm').on('click', function() {
        $('#subscribe').submit();
    });

    $('a.aural_choose').on('click', function(e) {
        e.preventDefault();
        if (!$(this).parent().parent().parent().hasClass('disabled')) {
            $(this).parent().parent().submit();
        }
    });

    $('a.package_choose').on('click', function(e) {
        e.preventDefault();
        if (!$(this).parent().parent().parent().hasClass('disabled')) {
            $(this).parent().parent().submit();
        }
    });

    //Contact us
    $('#faq-selector').on('change', function() {
        var id = parseInt($(this).val());
        if (!isNaN(id)) {
            $.ajax({
                method: 'POST',
                url: '/api/v1/faqs',
                data: JSON.stringify({id: id}),
                success: function(data) {
                    var template = '<p>{{body}}</p>';
                    $('#faq-show').html(Mustache.render(template, data));
                },
                error: function (jqXHR, textStatus, errorThrown) {

                }
            });
        }
    });

    var removeAudio = function() {
        $(this).fadeOut('slow');
        $(this).parent().find('input[type="file"]').fadeIn('slow');
    };

    $('a.audio_help_remove').on('click', removeAudio);
    $('a.audio_remove').on('click', removeAudio);

    var ajaxAnswers = function (part_id, option_id) {
        var template = '<tr><td>{{value}}</td><td class="icon_td"><input type="radio" name="correct[{{part_id}}]" value="{{choice_id}}" required></td></tr>';

        $.ajax({
            method: 'POST',
            url: '/api/v1/admin/answers',
            data: JSON.stringify({
                question_part_id: part_id,
                question_option_id: option_id
            }),
            success: function(data) {

                var table = $('#answers-' + part_id + ' tbody');
                table.fadeOut(function () {
                    table.html('');
                    $(data).each(function() {
                        table.append(Mustache.render(template, {value: this.value, part_id: part_id, choice_id: this.id})).fadeIn();
                    });
                    table.fadeIn();
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    };

    var questionSelector = function() {
        if($(this).val() !== '') {
            var option_id = $(this).val(), part_id = $(this).attr('data-id');
            ajaxAnswers(part_id, option_id);
        }
    };

    $('.question_selector').on('change', questionSelector);

    $('.subject_selector').on('change', function() {

        if ($(this).val() !== '') {
//            $('body').addClass('loading');
            var code = $(this).val(), part_id = $(this).attr('data-id');
            $('#answers-' + part_id + ' tbody').html('');

            $.ajax({
                method: 'POST',
                url: '/api/v1/admin/questions',
                data: JSON.stringify({
                    question_part_id: part_id,
                    code: code
                }),
                success: function(data) {
                    var input = $('#question-selector-' + part_id).find('.input');

                    if(data.length === 1) {
                        var question = data[0];

                        if (question.type.indexOf('bestanswer') > -1) {
                            if (question.type === 'question_bestanswer') {
                                input.html(Mustache.render('<input type="hidden" name="question[{{part_id}}]" value="{{question_option_id}}" />', {part_id: part_id, question_option_id: question.question_option_id}));
                                var template = '<input type="text" class="text" name="bespoke[{{part_id}}]" required/>';
                                input.append(Mustache.render(template, {part_id: part_id}));
                                input.parent().find('label').html('Enter question');
                            } else {
                                input.html('');
                                input.parent().find('label').html(question.question);
                            }

                            var answer = '<tr><td><input type="text" class="text" name="answer[{{part_id}}][{{i}}]" required/></td><td class="icon_td"><input type="radio" name="correct[{{part_id}}]" value="{{i}}" required></td></tr>';
                            var table = $('#answers-' + part_id + ' tbody');
                            for (var i = 1; i <= question.choices; i++) {
                                table.append(Mustache.render(answer, {part_id: part_id, i: i}));
                            }
                        }

                        if (question.type === 'radio') {
                            $('body').removeClass('loading');
                            ajaxAnswers(part_id, question.question_option_id);
                            input.parent().find('label').html(question.question);
                            input.html(Mustache.render('<input type="hidden" name="question[{{part_id}}]" value="{{question_option_id}}" />', {part_id: part_id, question_option_id: question.question_option_id}));
                        }
                    } else {
                        var template = '<select class="question_selector" data-id="{{part_id}}" name="question[{{part_id}}]" id="questions-{{part_id}}" required><option value="">Select...</option></select>';
                        input.html(Mustache.render(template, {part_id: part_id}));
                        var select = $('#questions-' + part_id);
                        $(data).each(function() {
                            select
                                .append($('<option>', {value: this.question_option_id}).text(this.question));
                        });
                        select.on('change', questionSelector);
                        input.parent().find('label').html('Choose question');
                        var table = $('#answers-' + part_id + ' tbody');
                        table.html('<tr><td colspan="2">Please select a question above</td></tr>');
                    }
                    $('body').removeClass('loading');
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $('body').removeClass('loading');
                }
            });
        }
    });

    var removeEmptyMedia = function() {
        $('.remove_empty_media').on('click', function() {
            $(this).parent().parent().parent().remove();
        });
    };

    //Pieces
    $('#piece-add-audio').on('click', function() {
        var template = '<div><div class="form_line inline">\n\
                <h4>Audio</h4>\n\
                <label>Upload</label><!--\n\
             --><div class="input">\n\
                    <input name="audio[]" type="file" />\n\
                </div>\n\
            </div>\n\
        \n\
        <div class="form_line inline">\n\
            <label>Title</label><!--\n\
         --><div class="input">\n\
                <input type="text" name="audio_title[]" class="text" />\n\
            </div>\n\
            <div class="btn btn_grey btn50"><a href="javascript:void(0);" class="remove_empty_media">Remove</a></div>\n\
        </div></div>';

        $(this).parent().parent().find('hr').before(template);
        removeEmptyMedia();
    });

    $('#piece-add-video').on('click', function() {
        var template = '<div>\n\
<div class="form_line inline">\n\
<h4>Video</h4>\n\
                <label>Vimeo id</label><!--\n\
             --><div class="input">\n\
                    <input type="text" name="video[]" class="text" />\n\
                </div>\n\
            </div>\n\
        \n\
        <div class="form_line inline">\n\
            <label>Title</label><!--\n\
         --><div class="input">\n\
                <input type="text" name="video_title[]" class="text" />\n\
            </div>\n\
            <div class="btn btn_grey btn50"><a href="javascript:void(0);" class="remove_empty_media">Remove</a></div>\n\
        </div></div>';

        $(this).parent().parent().find('hr').before(template);
        removeEmptyMedia();
    });

    //Packages
    $('#package-board').on('change', function() {
//        $('body').addClass('loading');
        if ($(this).val() !== '') {
            var board_id = $(this).val();
            var self = this;
            $.ajax({
                method: 'POST',
                url: '/api/v1/admin/grades',
                data: JSON.stringify({
                    exam_board_id: board_id
                }),
                success: function(data) {
                    var select = $('#package-grade');
                    select.html('');
                    for (var key in data) {
                        select.append($('<option>', {value: key}).text(data[key]));
                    }
                    $('body').removeClass('loading');
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $('body').removeClass('loading');
                }
            });
        } else {
            //console.log('No data');
        }

        return false;
    });

    //Banners
    $('a.remove_banner').on('click', function() {
        return confirm('Are you sure you want to delete this banner?');
    });

    $('a.delete_object').on('click', function() {
        return confirm('Are you sure you want to delete this ' + $(this).attr('data-type') + '?');
    });

    $('a.confirm').on('click', function() {
        return confirm($(this).attr('data-type'));
    });

    //Automatically submit a form
    if($('.auto_submit').length > 0){
        $('.auto_submit').submit();
    }

    //remove the loading stuff
    // $('html').removeClass('fouc');

    //scroll to item if there and if under 899 (put at bottom to ensure hidden things have been hidden)
    if ($('.no-flexbox #item').length > 0 && max_899) {
        $("html,body").animate({scrollTop:$("#item").position().top-20},2000,"easeInOutQuint");
    }

    //scroll to items if hashtag in url and if under 899
    if (window.location.hash == "#search" && max_899) {

        $("html,body").animate({scrollTop:$("#items").position().top-20},2000,"easeInOutQuint");
        return false;

    }

  /**
   * Handle get assessed upload
   */

  // Handle the Get Assessed form submission
  $('#get-assessed').submit(function(event) {

    // Build an array of our fields to use later
    $fields = [
      'grade_level',
      'assessment_adult',
      'guardian_first_name',
      'guardian_last_name',
      'guardian_email',
      'guardian_confirm_email',
      'playing_time',
      'composer',
      'title',
      'file',
      'terms_agreed'
    ];

    // Stop the form from submitting the normal way
    event.preventDefault();

    // Hide our error message block
    $('#form-errors').hide().children('ul').html();

    // Show our progress bar
    $('#progressbar').show();

    // Hide our 'submit' button and show our progress message
    $('button#buttonSubmit').hide();
    $('#progressMessage').append('Your file is now being processed, please be aware this can take a while.');

    // Remove our field specific errors
    $.each($fields, function(key, value) {
        $('#'+ value +'_field').removeClass('error');
        $('#'+ value +'_field').find('.note.error').remove();
    });

    // Setup out FormData class
    var formData = new FormData();

    // Append our form values to FormData
    formData.append('grade_level', $("select[name=grade_level] option:selected").val());
    formData.append('assessment_adult', $("select[name=assessment_adult] option:selected").val());
    formData.append('guardian_first_name', $('input[name=guardian_first_name]').val());
    formData.append('guardian_last_name', $('input[name=guardian_last_name]').val());
    formData.append('guardian_email', $('input[name=guardian_email]').val());
    formData.append('guardian_confirm_email', $('input[name=guardian_confirm_email]').val());
    formData.append('playing_time', $("select[name=playing_time] option:selected").val());
    formData.append('composer', $('input[name=composer]').val());
    formData.append('title', $('input[name=title]').val());
    formData.append('terms_agreed', $('input[name=terms_agreed]').is(":checked") ? 1 : '');

    // Grab our file selector
    var files = jQuery('#file')[0].files;

    // If files are found, append them to FormData
    if (files.length) {
      formData.append('file', files[0]);
    }

    // Handle the display of upload progress
    var showProgress = function(evt) {
        if (evt.lengthComputable) {
            var percentComplete = (evt.loaded / evt.total) * 100;
            //$('#progressbar').progressbar("option", "value", percentComplete );
            console.log(percentComplete);

            $("#progressbar").val(percentComplete);
            // $("#progressbar").html('<div id="progress-status">' + percentComplete +' %</div>')
        }
    }

    // Send off our ajax request
    $.ajax({
      type: 'POST',
      url: 'api/v1/get-assessed/apply',
      data: formData,
      //dataType: 'json',
      //encode: true,
      processData: false,
      contentType: false,
      xhr: function() {
        myXhr = $.ajaxSettings.xhr();

        if(myXhr.upload){
          myXhr.upload.addEventListener('progress',showProgress, false);
        } else {
          console.log("Upload progress is not supported.");
        }
        return myXhr;
      }
    })
    // Handle successful callbacks
    .success(function(data) {
      // Hide our progress bar
      $('#progressbar').hide();

      // Re-show our 'submit' button and hide our progress message
      $('a#buttonCart').show();
      $('#progressMessage').remove();

      // ALL GOOD! just show the success message!
      $('#progressMessage').append('Your file is now being processed, please be aware this can take a while.');
    })
    // using the fail promise callback
    .fail(function(data) {
      // Grab any errors
      $errors = data.responseJSON;

      // Build our error message block
      errorsHtml = '';

      // Loop over the errors array
      $.each($errors['errors'], function(key, value) {
        // Add the first error for each field to the ul
        errorsHtml += '<li>' + value[0] + '</li>';

        // Give the fields an error class and add a note about the error
        $('#'+ key +'_field').addClass('error'); // add the error class to show red input
        $('#'+ key +'_field').append('<div class="note error">' + value[0] + '</div>'); // add the actual error message under our input
      });

      // Show our errors
      $('#form-errors').show().children('ul').html(errorsHtml);

      // Hide our progress bar
      $('#progressbar').hide();

      // Re-show our 'submit' button and hide our progress message
      $('button#buttonSubmit').show();
      $('#progressMessage').remove();
    });
  });
});